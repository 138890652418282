const MAX_FONT_SIZE = 18;
export const calcIdealFontSize = (container: HTMLDivElement): string => {
    const clone: HTMLDivElement = container.cloneNode(true) as HTMLDivElement;
    clone.style.visibility = 'hidden';
    clone.style.zIndex = '-1';
    clone.style.fontSize = `${MAX_FONT_SIZE}px`;
    container.parentElement!.appendChild(clone);
    let isOverflowing = true;
    while (isOverflowing) {
        const computedFontSize = window.getComputedStyle(clone).fontSize;
        clone.style.fontSize = `${parseInt(computedFontSize) - 1}px`
        isOverflowing = (clone.clientHeight < clone.scrollHeight) || (clone.clientWidth < clone.scrollWidth);
    }
    container.parentElement!.removeChild(clone);
    return clone.style.fontSize;
}

export const measureFontSize = (el: HTMLElement) => parseFloat(window.getComputedStyle(el).fontSize)
