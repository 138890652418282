import React from 'react'

export interface CodeckLayoutConfig {
    cardThumbnailDimensions: React.CSSProperties,
    cardBorderRadius: string
}

export const CodeckLayoutConfigContext = React.createContext<CodeckLayoutConfig>({
    cardThumbnailDimensions: {
        height: '150px',
        width: '300px'
    },
    cardBorderRadius: '5px'
})
