import React, {useContext, useEffect, useRef, useState} from 'react'
import {DeckContext} from '../DeckContext';
import DOMPurify from 'dompurify';
import {FlashcardFaceThumbnail, renderFlashcardMarkup} from '../FlashcardFaceView';
import {CodeckNavbar} from '../CodeckNavbar';
import {Button, ButtonGroup, Dropdown, Tab, Tabs} from 'react-bootstrap';
import * as Bootstrap from 'react-bootstrap';
import {CodeckSourceEditor} from '../lang/CodeckSourceEditor';

export interface FaceReference {
    faceId: string,
    cardId: string
}


export interface FaceEditorProps {
    lastCommittedMarkup: string,

    // Context to be displayed the user interface.
    // Might be undefined if some of the local deck state was curropted somehow.
    faceTitle?: string,
    cardTitle?: string,
    uniqueFaceId: string,

    /**
     * Commits the new markup and optionally opens the next empty face in
     * the card.
     */
    commit: (newMarkup: string, openNextFace: boolean) => void,

    /**
     * Closes the editor and discards all changes.
     */
    discard: () => void
}

export const FaceEditorGuard = (props: {
    face?: FaceReference,

    // Changes the face object which is passed to this face editor guard.
    // Triggers a complete re-render of this component.
    // This state is stored inside of the DeckListView.
    close: (moveToNextFace: boolean) => void
}) => {
    const deckContext = useContext(DeckContext);

    // If no face is given, then the editor is closed.
    if (!props.face) return <React.Fragment/>;
    const face = props.face;
    const faceTitle: string | undefined = deckContext.deck.faceTypes[face.faceId].title;
    const cardTitle: string | undefined = deckContext.cards[face.cardId]?.[deckContext.deck.searchKeyFaceId]?.markup;
    const lastCommittedMarkup = deckContext.cards[face.cardId]?.[face.faceId]?.markup;
    console.log('markup is now ' + lastCommittedMarkup);

    // The editor cannot be rendered if we don't have a starting point for the content.
    if (lastCommittedMarkup === undefined) return <React.Fragment/>

    const commit = (newMarkup: string, moveToNextFace: boolean) => {
        // Trigger a complete re-render of this FaceGuard by closing the current
        // face and optionally switching to the next face on this card.
        props.close(moveToNextFace);
        // After killing this instance of the component via props.close(),
        // commit the changes to the cache.
        deckContext.updateCardFace(face.cardId, face.faceId, newMarkup);
    }

    // Trigger a re-render of the guard by closing the current face
    // and not moving to the next face.
    const discard = () => props.close(false);

    return (
        <FaceEditor
            lastCommittedMarkup={lastCommittedMarkup}
            faceTitle={faceTitle}
            cardTitle={cardTitle}
            commit={commit}
            discard={discard}
            uniqueFaceId={`${face.cardId + '/' + face.faceId}`}
        />
    );
}

// The face editor will never be opened if
export const FaceEditor = (props: FaceEditorProps): JSX.Element => {
    const [uncommittedMarkup, setUncommitedMarkup] = useState<string>('');
    useEffect(() => {
        setUncommitedMarkup(props.lastCommittedMarkup);
    }, [props.uniqueFaceId, props.lastCommittedMarkup]);

    const deckContext = useContext(DeckContext);

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = '';
        }
    }, [])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            const isCommandPressed = e.metaKey || e.ctrlKey;
            if (e.code === 'Escape') {
                props.commit(uncommittedMarkup, false);
                return;
            }
            if (isCommandPressed && e.code === 'KeyS') {
                e.preventDefault();
                props.commit(uncommittedMarkup, true);
            }
        }
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener)
    })

    const breaklessTitle = DOMPurify.sanitize(renderFlashcardMarkup(props.cardTitle), {
        // TODO: Consider forbidding these tags anywhere we use a search key face?
        FORBID_TAGS: ['br', 'p', 'pre']
    }).trim();

    const [pageHeight, setPageHeight] = useState<number>(window.innerHeight);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const listener = () => { setPageHeight(window.innerHeight); }
        window.addEventListener('resize', listener);
        return () => { window.removeEventListener('resize', listener); }
    }, []);

    return (
        <React.Fragment>
            <div style={{position: 'fixed', width: '100%', height: pageHeight, zIndex: 2, background: 'white', overflow: 'scroll'}}>
                <CodeckNavbar mode={'none'}>
                    <Dropdown as={ButtonGroup} align={'end'}>
                        <Button onClick={() => props.commit(uncommittedMarkup, false)} variant={'light'}>Done</Button>
                        <Dropdown.Toggle split variant={'light'} />

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={props.discard}>Discard</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </CodeckNavbar>
                <br/>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
                    <FlashcardFaceThumbnail markup={uncommittedMarkup}/>
                </div>
                <Bootstrap.Container fluid>
                    <br/>
                    <h3>
                        <span dangerouslySetInnerHTML={{__html: breaklessTitle}}/>
                        <span>:&nbsp;</span>
                        <span>{props.faceTitle}</span>
                    </h3>
                    <p>in {deckContext.deck.title}</p>
                </Bootstrap.Container>

                <Tabs defaultActiveKey="edit">
                    <Tab eventKey="edit" title="Editor">
                        <div ref={ref}>
                            <CodeckSourceEditor
                                autoFocus={true}
                                sourceCode={uncommittedMarkup!}
                                setSourceCode={setUncommitedMarkup}
                                style={{width: '100vw', height: `${pageHeight - (ref.current?.offsetTop || 0)}px`}}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="format-help" title="Formatting Help">
                        <Bootstrap.Container fluid>
                            <Bootstrap.Row style={{ marginTop: '10px' }}>
                                <h5>Inline Math</h5>
                                <p>
                                    LaTeX can be inlined by surrounding it in dollar signs.
                                </p>
                                <CodeckSourceEditor style={{ width: 'fit-content' }} sourceCode={'Consider the variables $x^2$, $y^2$, and $z^2$.'} setSourceCode={() => {}}/>
                            </Bootstrap.Row>
                            <br/>
                            <Bootstrap.Row>
                                <h5>Math Block</h5>
                                <p>To disable text-wrapping and center an equation, use a Math block.</p>
                                <CodeckSourceEditor style={{ width: 'fit-content' }} sourceCode={'{{#math}}\na^2+b^2=c^2\n{{/math}}'} setSourceCode={() => {}}/>
                            </Bootstrap.Row>
                            <br/>
                            <h5>Markdown</h5>
                            <p>
                                A subset of Markdown is supported.
                            </p>
                            <h5>HTML</h5>
                            <p>
                                A subset of HTML is supported.
                            </p>
                        </Bootstrap.Container>
                    </Tab>
                    <Tab eventKey="keyboard-help" title="Keyboard Shortcuts">
                        <Bootstrap.Container fluid>
                            <br/>
                            <p>The following keyboard shortcuts are available inside the editor.</p>
                            <p>NOTE: On Windows Machines use the <b>Ctrl</b> key can be used as a substitute for the <b>Cmd</b> key.</p>
                            <h5>Esc</h5>
                            <p>The escape key will close the editor and save all changes.</p>
                            <h5>Cmd+S</h5>
                            <p>Saves all changes and then switches the editor to the next blank face of the current card.
                                If all faces are filled, closes the editor.</p>
                        </Bootstrap.Container>

                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    );
}
