import React, {useState} from 'react';
import './App.css';
import {RootCodeckAuthProvider} from './AuthContext';
import {DeckListProvider} from './DeckList';
import {DeckListView} from './DeckListView';
import {HashRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {DeckContextProvider} from './DeckContext';
import {ExplodedDeckView} from './ExplodedDeckView';
import {StudyDeckView} from './StudyDeckView';
import {AboutCodeckView} from './AboutCodeckView';
import {CodeckLangSupportProvider} from './lang/CodeckLangSupport';
import {CodeckSourceEditor} from './lang/CodeckSourceEditor';

export const Playground = (): JSX.Element => {
    const [userSourceCode, setUserSourceCode] = useState<string>('');
    return (
        <div style={{ height: '100vh', width: '100vw', border: 'solid black 3px' }}>
                <CodeckSourceEditor sourceCode={userSourceCode} setSourceCode={setUserSourceCode}/>
        </div>
    )
}

function App() {
    return (
        <CodeckLangSupportProvider>
        <Router>
            <Switch>
                <Route path={'/app'}>
                        <RootCodeckAuthProvider>
                            <Switch>
                                <Route path={'/app/decks'}>
                                    <DeckListProvider>
                                        <DeckListView/>
                                    </DeckListProvider>
                                </Route>
                                <Route path={'/app/edit/deck/:id'}>
                                    <DeckContextProvider>
                                        <ExplodedDeckView/>
                                    </DeckContextProvider>
                                </Route>
                                <Route path={'/app/study/deck/:id'}>
                                    <StudyDeckView/>
                                </Route>
                                <Route path={'/app/playground'}>
                                    <Playground/>
                                </Route>
                                <Route path={'/app'}>
                                    <Redirect to={'/app/decks'}/>
                                </Route>
                            </Switch>
                        </RootCodeckAuthProvider>
                </Route>
                <Route>
                    <AboutCodeckView/>
                </Route>
            </Switch>
        </Router>
        </CodeckLangSupportProvider>
    );
}

export default App;
