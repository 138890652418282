import React from 'react'
import * as Bootstrap from 'react-bootstrap'
export const CodeckBrand = (): JSX.Element => {
    return (
        <Bootstrap.Navbar.Brand style={{ display: 'flex' }}>
            <img src={'/logo-white.svg'} style={{width: '40px', marginRight: '7px'}}/>
            <span style={{ fontFamily: 'logo-font', color: 'white', fontSize: '20px' }}>Codeck</span>
        </Bootstrap.Navbar.Brand>
    )
}
