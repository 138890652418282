import {DeckPointer, FlashcardDeck, DeckList, DeckListCoverage} from 'dto-interfaces'
import {deepCopy} from './util/ObjectUtils';
import React, {useCallback, useContext, useEffect, useState} from 'react'
import * as uuid from 'uuid'
import {AuthContextInterface, CodeckAuthContext} from './AuthContext';
import {smartFetch} from './util/FetchUtils';

export const uploadDeck = async (auth: AuthContextInterface, id: string, deck: FlashcardDeck): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${id}`, {
        method: 'POST',
        headers: {
            ...(await auth.getAuthHeaders()),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(deck)
    });
    if (!response.ok) throw new Error(response.statusText);
}

export const sendDeleteDeckRequest = async (auth: AuthContextInterface, id: string): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${id}`, {
        method: 'DELETE',
        headers: {
            ...(await auth.getAuthHeaders())
        }
    });
    if (!response.ok) throw new Error(response.statusText);
}

export const sendSetCoverageTimeframeRequest = async (auth: AuthContextInterface, newSelectedTimeframe: number): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/coverage/selected-timeframe`, {
        method: 'PUT',
        body: JSON.stringify(newSelectedTimeframe),
        headers: {
            'Content-Type': 'application/json',
            ...(await auth.getAuthHeaders())
        }
    });
    if (!response.ok) throw new Error(response.statusText);
}

export const fetchDecks = async (auth: AuthContextInterface): Promise<DeckList> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck`, {
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json'
        }
    });
    return await response.json();
}

export const fetchCoverageReports = async (auth: AuthContextInterface): Promise<DeckListCoverage> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/coverage`, {
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json'
        }
    });
    return await response.json();
}

export const defaultDeck = (title: string): FlashcardDeck => {
    const searchKeyFaceId = uuid.v4();
    const timestamp = Date.now();
    return {
        title,
        searchKeyFaceId,
        faceTypes: {
            [searchKeyFaceId]: {
                title: 'Term',
                isLabel: true,
                displayOrderHint: timestamp - 1
            },
            [uuid.v4()]: {
                title: 'Definition',
                isLabel: true,
                displayOrderHint: timestamp
            }
        }
    }
}

type DeckListCache = {
    [key: string]: DeckPointer
}

const add = (id: string, deck: DeckPointer) => (state: DeckListCache) => {
    const newState = deepCopy(state);
    newState[id] = deck;
    return newState;
}

const remove = (id: string) => (state: DeckListCache) => {
    const newState = deepCopy(state);
    delete newState[id];
    return newState;
}

export const asArray = (state: DeckListCache) => Object.keys(state)
    .map(id => ({ id, ...state[id] }));

export interface DeckListContextInterface {
    pull: () => void,
    createDeck: (title: string) => Promise<string>,
    deleteDeck: (id: string) => Promise<void>,
    readonly cache: DeckListCache,
    lastPulledAt?: Date,
    isSubscribed?: boolean,
    syncing: boolean,
    coverage?: DeckListCoverage,
    setSelectedCoverageTimeframe: (newTimeframe: number) => void
}

export const DeckListContext = React.createContext<DeckListContextInterface>({
    pull: () => { throw new Error('not implemented') },
    createDeck: () => { throw new Error('not implemented') },
    deleteDeck: () => { throw new Error('not implemented') },
    cache: {},
    isSubscribed: false,
    get coverage(): DeckListCoverage { throw new Error('not implemented') },
    syncing: false,
    setSelectedCoverageTimeframe: () => { throw new Error('not implemented'); }
});

export const DeckListProvider = (props: React.PropsWithChildren<{}>) => {
    const auth = useContext(CodeckAuthContext);
    const [cache, setCache] = useState<DeckListCache>({});
    const [syncing, setSyncing] = useState<boolean>(false);
    const [lastPulledAt, setLastPulledAt] = useState<Date>();
    const [isSubscribed, setSubscribed] = useState<boolean>();
    const [coverage, setCoverage] = useState<DeckListCoverage>();
    const [pullRequested, setPullRequested] = useState<number>(0);

    const createDeck = (title: string) => {
        const id = uuid.v4();
        const deck = defaultDeck(title);
        return uploadDeck(auth, id, deck)
            .then(() => setCache(add(id, deck)))
            .then(() => id)
    };

    const deleteDeck = (id: string) => {
        return sendDeleteDeckRequest(auth, id)
            .then(() => setCache(remove(id)))
            .catch(console.error)
    };

    const pullDecks: () => Promise<void> = useCallback(async () => {
            setSyncing(true);
            try {
                const decks = await fetchDecks(auth);
                setCache(decks.index);
                setLastPulledAt(new Date());
                setSubscribed(decks.isSubscribed);
            } catch (e) {
                console.log(e);
            }
            setSyncing(false);
        }, [auth])

    const pullCoverage: () => Promise<void> = useCallback(async () => {
        const coverage = await fetchCoverageReports(auth);
        setCoverage(coverage);
    }, [auth]);

    const setSelectedCoverageTimeframe = (newTimeframe: number) => {
        // Purposefully optimistic
        setCoverage(coverage => {
            if (!coverage) return;
            const newCoverage = deepCopy(coverage);
            newCoverage.selectedCoverageTimeframe = newTimeframe;
            return newCoverage;
        });
        sendSetCoverageTimeframeRequest(auth, newTimeframe)
            .catch(console.error);
    }

    useEffect(() => {
        pullDecks().catch(console.error);
        pullCoverage().catch(console.error);
    }, [pullRequested, pullDecks, pullCoverage]);

    return (
        <React.Fragment>
            <DeckListContext.Provider value={{
                syncing,
                isSubscribed,
                createDeck,
                deleteDeck,
                pull: () => setPullRequested(count => count + 1),
                cache,
                lastPulledAt,
                coverage,
                setSelectedCoverageTimeframe
            }}>
                {props.children}
            </DeckListContext.Provider>
        </React.Fragment>
    )
}
