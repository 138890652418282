import {DeckContext} from './DeckContext';
import {useContext, useEffect, useRef, useState} from 'react';
import DOMPurify from 'dompurify';
import Handlebars from 'handlebars'
import React from 'react'
import * as Bootstrap from 'react-bootstrap'
import * as katex from 'katex'
import 'katex/dist/katex.css'
import marked from 'marked'
import highlightjs from 'highlight.js'
import './FlashcardFaceView.scss'
import {isSynced} from './FacesCache';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {deepCopy} from './util/ObjectUtils';
import {calcIdealFontSize} from './util/DOMCalc';

marked.use({
    // breaks: true,
    gfm: true
})

marked.setOptions({
    highlight: function(code, lang) {
        const language = highlightjs.getLanguage(lang) ? lang : 'plaintext';
        return highlightjs.highlight(code, { language }).value;
    },
})


export const expandShorthandMathBlocks = (markup: string) => {
    let expanded = '';
    let fenceCount = 0;
    for (let i = 0; i < markup.length; i++) {
        const isFenceChar = markup[i] === '$';
        if (isFenceChar) {
            fenceCount++;
            if (fenceCount % 2 === 0) {
                expanded += '{{/inline-math}}'
            } else {
                expanded += '{{#inline-math}}'
            }
        } else {
            expanded += markup[i];
        }
    }
    if (fenceCount % 2 !== 0) {
        expanded += '{{/inline-math}}'
    }
    return expanded;
}

export const renderFlashcardMarkup = (
    markup?: string
) => {
    if (markup) {
        markup = expandShorthandMathBlocks(markup);
    }

    const handlebars = Handlebars.create();
    handlebars.registerHelper('inline-math', function(options) {
        // @ts-ignore
        const latexMarkup = options.fn(this);
        let rendered = katex.renderToString(latexMarkup, { throwOnError: false });
        return new Handlebars.SafeString(`${rendered}`);
    });
    handlebars.registerHelper('math', function(options) {
        // @ts-ignore
        const latexMarkup = options.fn(this);
        let rendered = katex.renderToString(latexMarkup, { throwOnError: false });
        return new Handlebars.SafeString(`<pre style="font-size: 150% !important; text-align: center">${rendered}</pre>` + '\n');
    })

    let userContent: string
    try {
        const faceTemplate = handlebars.compile(markup || '');
        userContent = faceTemplate({});
    } catch (e) {
        userContent = ''
    }

    userContent = marked.parse(userContent);

    return DOMPurify.sanitize(userContent, {
        // Heading tags breaks the nooverflow system
        FORBID_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img']
    });
}

const flexibleFlashcardStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    overflowWrap: 'break-word',
    wordBreak: 'keep-all',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center'
}
export const FlashcardFaceContentView = (props: { markup: string, resizable?: boolean }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        if (props.resizable) {
            const listener = (event: Event) => setScreenWidth(window.innerWidth);
            window.addEventListener('resize', listener);
            return () => window.removeEventListener('resize', listener);
        }
    }, []);

    const renderedMarkup = renderFlashcardMarkup(props.markup);

    const ref = useRef<HTMLDivElement>(null);
    const [fontSize, setFontSize] = useState<string>();
    const [foundIdealSize, setFoundIdealSize] = useState<boolean>(false);
    useEffect(() => {
        if (ref.current) {
            setFontSize(calcIdealFontSize(ref.current));
            setFoundIdealSize(true);
        }
    }, [ref, props.markup, screenWidth]);

    return (
        <div ref={ref} style={{...flexibleFlashcardStyle, fontSize, opacity: foundIdealSize ? '1' : '0' }} className={'code-containable-flashcard'}>
            <div style={{    padding: '5px'}} dangerouslySetInnerHTML={{__html:  renderedMarkup }} />
        </div>
    );
}

export const FlexibleFlashcardFaceView = (props: { markup?: string, resizable?: boolean }) => {
    const flashcardStyle: React.CSSProperties = {
        borderRadius: '4px',
        borderColor: 'black',
        borderWidth: '3px',
        background: 'white',
        width: '100%',
        height: '100%'
    }

    // If markup is undefined, assume it has not been loaded yet and present a placeholder.
    if (props.markup === undefined) {
        return (
            <div style={{...flashcardStyle, borderStyle: 'solid'}}>
                <div style={{ padding: '10px' }}>
                    <Bootstrap.Placeholder style={{width: '100%'}} />
                    <Bootstrap.Placeholder style={{width: '100%'}} />
                    <Bootstrap.Placeholder style={{width: '100%'}} />
                </div>
            </div>
        )
    }

    // For empty flashcards show a dashed border as a way to call attention to the missing information.
    const borderStyle = props.markup?.trim()?.length === 0 ? 'dashed' : 'solid';

    return (
        <div style={{...flashcardStyle, borderStyle}}>
            <FlashcardFaceContentView markup={props.markup!} resizable={props.resizable}/>
        </div>
    );
}

export const FlashcardFaceThumbnail = (props: { markup?: string }) => {
    return (
        <div style={{ width: '300px', height: '150px' }}>
            <FlexibleFlashcardFaceView markup={props.markup}/>
        </div>
    )
}

export const CachedFlashcardFaceThumbnail = (props: {cardId: string, faceTypeId: string}): JSX.Element => {
    const { cards } = useContext(DeckContext);
    const card = cards?.[props.cardId];
    const face = card?.[props.faceTypeId];

    return (
        <div style={{ height: '150px', width: '300px', position: 'relative' }}>
            <FaceSyncStatus cardId={props.cardId} faceTypeId={props.faceTypeId}/>
            <FlashcardFaceThumbnail markup={face?.markup}/>
        </div>

    )
}

const FaceSyncStatus = (props: { cardId: string, faceTypeId: string }): JSX.Element => {
    const { cards } = useContext(DeckContext);

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            This card has been saved to the cloud.
        </Tooltip>
    );


    if (isSynced(cards, props.cardId, props.faceTypeId)) {
        return (
            <div style={{ position: 'absolute', bottom: 0, right: 0, paddingRight: '3px', paddingBottom: '3px' }}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <span>{ '✓' }</span>
                </OverlayTrigger>
            </div>
        )
    }

    return <React.Fragment/>

}
