import {FlashcardFace} from 'dto-interfaces'
import {deepCopy} from './util/ObjectUtils';

export type CachedFlashcardFace = FlashcardFace & {
    syncedAt?: number
}

export interface FacesCache {
    [key: string]: { // cardId
        [key: string]: CachedFlashcardFace // faceTypeId
    }
}

export const purgeByCardId = (cardId: string) => (cache: FacesCache) => {
    const updatedCache = deepCopy(cache);
    delete updatedCache[cardId];
    return updatedCache;
}

export const purgeByFaceTypeId = (faceTypeId: string) => (cache: FacesCache) => {
    const updatedCache = deepCopy(cache);
    for (const cardId of Object.keys(updatedCache)) {
        delete updatedCache[cardId][faceTypeId];
    }
    return updatedCache;
}

export const hydrate = (cardId: string, faceTypeId: string, timestamp: number, markup: string) => (cache: FacesCache) => {
    // Don't update if newer value exists in cache
    if ((cache[cardId]?.[faceTypeId]?.lastUpdatedAt || -1) > timestamp) return cache;

    const updatedCache = deepCopy(cache);
    if (!updatedCache[cardId]) updatedCache[cardId] = {};
    updatedCache[cardId][faceTypeId] = { syncedAt: timestamp, lastUpdatedAt: timestamp, markup }
    return updatedCache;
}

export const set = (cardId: string, faceTypeId: string, timestamp: number, markup: string) => (cache: FacesCache) => {
    const updatedCache = deepCopy(cache);
    if (!updatedCache[cardId]) updatedCache[cardId] = {};
    updatedCache[cardId][faceTypeId] = { markup, lastUpdatedAt: timestamp }
    return updatedCache;
}

export const addFaceTypeToFaceCache = (faceTypeId: string) => (cache: FacesCache) => {
    const updatedCache = deepCopy(cache);
    const timestamp = Date.now();
    for (const cardId of Object.keys(cache)) {
        updatedCache[cardId][faceTypeId] = { markup: '', syncedAt: timestamp, lastUpdatedAt: timestamp }
    }
    return updatedCache;
}

export const isSynced = (cache: FacesCache, cardId: string, faceTypeId: string) => {
    const entry = cache[cardId]?.[faceTypeId];
    if (!entry) return false;
    return (entry.syncedAt === entry.lastUpdatedAt);
}
