import React, {useEffect, useState} from 'react';
import deploymentEnvironment from './deployment-environment.json';
import {AmplifyAuthenticator, AmplifySignOut, AmplifySignUp} from '@aws-amplify/ui-react';
import Amplify, { Hub, Auth } from 'aws-amplify'
import * as Bootstrap from 'react-bootstrap'

export interface AuthContextInterface {
    getAuthHeaders: () => Promise<Record<string, string>>,
    username?: string,
    fireflyApiUrl?: string,
    isBlindTrust: boolean,
    logout: () => void
}

const CodeckAuthContext = React.createContext<AuthContextInterface>({
    username: undefined,
    fireflyApiUrl: undefined,
    getAuthHeaders: async () => ({}),
    isBlindTrust: false,
    logout: () => {}
});

const BlindTrustCodeckAuthProvider: React.FC = (props) => {
    const authContext: AuthContextInterface = {
        getAuthHeaders: async () => ({
            'Blind-Trust-Username': 'Alice'
        }),
        username: 'Alice',
        fireflyApiUrl: deploymentEnvironment.apiUrl,
        isBlindTrust: true,
        logout: () => {}
    }
    return (
        <CodeckAuthContext.Provider value={authContext}>
            {props.children}
        </CodeckAuthContext.Provider>
    )
}

const AwsCognitoCodeckAuthProvider: React.FC = (props) => {
    const [username, setUsername] = useState<string>('');

    useEffect(() => {
        // @ts-ignore
        const listener = (eventContainer) => {
            console.log(eventContainer)
            switch (eventContainer.payload.event) {
                case 'signIn':
                    setUsername(eventContainer.payload.data.username)
                    break;
                case 'signOut':
                    setUsername('')
                    break;
                case 'tokenRefresh':
                    break;
            }
        }

        Hub.listen('auth', listener);
        return () => Hub.remove('auth', listener);
    }, []);

    useEffect(() => {
        Auth.currentUserInfo()
            .then(info => setUsername(info.username))
            .catch(console.error)
    }, []);

    return (
        <CodeckAuthContext.Provider value={{
            username,
            fireflyApiUrl: deploymentEnvironment.apiUrl,
            getAuthHeaders: async () => {
                const currentSession = await Auth.currentSession();
                return {
                    'Authorization': `Bearer ${currentSession.getIdToken().getJwtToken()}`
                }
            } ,
            isBlindTrust: false,
            logout: () => Auth.signOut()
        }}>
            {props.children}
        </CodeckAuthContext.Provider>
    )
}

const RootCodeckAuthProvider: React.FC = (props) => {
    if (deploymentEnvironment.authMode === 'BLIND_TRUST_USERNAME') {
        return <BlindTrustCodeckAuthProvider>{props.children}</BlindTrustCodeckAuthProvider>
    }

    if (deploymentEnvironment.authMode === 'AWS_COGNITO_JWT') {
        return (
            <AmplifyAuthenticator usernameAlias={'email'}>
                <AmplifySignUp slot="sign-up" usernameAlias={'email'} formFields={[
                    {
                        type: "email",
                        label: "Email Address",
                        placeholder: "abc123@my.fsu.edu",
                        inputProps: { required: true, autocomplete: "username" },
                    },
                    {
                        type: "password",
                        label: "Password",
                        placeholder: "Password",
                        inputProps: { required: true, autocomplete: "new-password" },
                    },
                ]}/>
                <AwsCognitoCodeckAuthProvider>{props.children}</AwsCognitoCodeckAuthProvider>
            </AmplifyAuthenticator>
        )
    }

    throw new Error('This firefly/web-ui build has not been bound to a backend.');
}

export { CodeckAuthContext, RootCodeckAuthProvider };
