import highlightjs, {Language} from 'highlight.js';
import React, {SetStateAction, useContext, useEffect, useState} from 'react'

export interface CodeckLangSupport {
    /**
     * When true the 'codeck-lang' language can be used with {@link highlightjs} highlight
     * function. Attempting to use the codeck-lang language when this is false will result
     * in an error being thrown by {@link highlightjs}.
     */
    isReady: boolean
}

/**
 * This Context can be used to determine whether or not the codeck-lang highlight.js
 * language definition has been loaded yet. It is consumed by {@link Index}.
 * The language definition can be loaded for any group of components by wrapping those
 * components in {@link CodeckLangSupportProvider}.
 */
export const CodeckLangSupportContext = React.createContext<CodeckLangSupport>({ isReady: false });

/**
 * Provides {@link CodeckLangSupportContext} and registers the codeck-lang language definition
 * with highlight.js. This should be an ancestor to all {@link Index} components.
 */
export const CodeckLangSupportProvider = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const [isReady, setReady] = useState<boolean>(false);

    const language: Language = {
        name: 'codeck-lang',
        subLanguage: ['markdown'],
        contains: [
            {
                subLanguage: ['latex'],
                begin: '\\$',
                end: '\\$',
                endSameAsBegin: true,
                relevance: 100,
                excludeBegin: true,
                excludeEnd: true,
            },
            {
                begin: '{{#math}}',
                end: '{{/math}}',
                excludeBegin: true,
                excludeEnd: true,
                subLanguage: ['latex'],
            },

        ]
    }

    useEffect(() => {
        highlightjs.registerLanguage(language.name!, () => language);
        setReady(true);
        return () => {
            setReady(false);
            highlightjs.unregisterLanguage(language.name!)
        }
    }, []);

    return (
        <CodeckLangSupportContext.Provider value={{ isReady }}>
            {props.children}
        </CodeckLangSupportContext.Provider>
    )
}
