import React, {FormEvent, SetStateAction, useCallback, useContext, useEffect, useRef, useState} from 'react'
import * as Bootstrap from 'react-bootstrap';
import {DeckContext} from './DeckContext';
import {CodeckAuthContext} from './AuthContext';
import {Link} from 'react-router-dom'
import {FlashcardFaceType} from 'dto-interfaces'
import {CachedFlashcardFaceThumbnail} from './FlashcardFaceView';
import Fuse from 'fuse.js';
import {FacesCache, isSynced} from './FacesCache';
import {useInView} from 'react-intersection-observer';
import {FiSettings} from '@react-icons/all-files/fi/FiSettings'
import {ImStack} from '@react-icons/all-files/im/ImStack'
import {Dropdown} from 'react-bootstrap';
import {CodeckNavbarSignedIn} from './CodeckNavbar';
import {MdFilterNone} from '@react-icons/all-files/md/MdFilterNone';
import {BsFilter} from '@react-icons/all-files/bs/BsFilter';
import {FaceEditorGuard, FaceReference} from './editor/FaceEditorGuard';
import {useHistory} from 'react-router-dom'
import {MdPriorityHigh} from '@react-icons/all-files/md/MdPriorityHigh'

export interface ExplodedDeckViewContextInterface {
    readonly query: string,
    readonly setQuery: React.Dispatch<SetStateAction<string>>,
    readonly setCurrentlyOpenFace: (newFace?: FaceReference) => void,
    readonly currentlyOpenFace?: FaceReference,
    readonly filteredCards: Array<string>
}

export const ExplodedDeckViewContext = React.createContext<ExplodedDeckViewContextInterface>({
    get query(): string {
        throw new Error('not implemented')
    },
    setQuery: () => {
        throw new Error('not implemented')
    },
    setCurrentlyOpenFace: () => {
        throw new Error('not implemented')
    },
    get currentlyOpenFace(): undefined { throw new Error('not implemented') },
    get filteredCards(): Array<string> { throw new Error('not implemented') },
});

export const CardsSearchBar = (): JSX.Element => {
    const { deck, cards, createNewCard } = useContext(DeckContext);
    const { query, setQuery, currentlyOpenFace, filteredCards, setCurrentlyOpenFace } = useContext(ExplodedDeckViewContext);

    const searchBoxRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        const keyListener = (event: KeyboardEvent) => {
            const isCommandPressed = event.ctrlKey || event.metaKey
            if (isCommandPressed) {
                if (event.code === 'KeyF') {
                    event.preventDefault();
                    searchBoxRef.current?.focus();
                }
            }
        }
        document.addEventListener('keydown', keyListener);
        return () => document.removeEventListener('keydown', keyListener);
    })

    const onClickCreateFlashcard = () => {
        createNewCard(query);
    };

    const isConflictingSearchKey = Object.values(cards)
        .map(card => card[deck.searchKeyFaceId])
        .filter(face => face)
        .some(face => face.markup.trim().toLowerCase() === query.trim().toLowerCase());

    const handleUniversalSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (filteredCards.length === 0 && !isConflictingSearchKey) {
            onClickCreateFlashcard()
            return;
        }
        if (filteredCards.length > 0) {
            const cardId = filteredCards[0];
            let emptyFaceTypeId: string | undefined = undefined;
            const faceTypeIds = Object.keys(deck.faceTypes)
                .sort((x, y) => deck.faceTypes[x].displayOrderHint - deck.faceTypes[y].displayOrderHint)
            for (const faceTypeId of faceTypeIds) {
                if (!cards[cardId]?.[faceTypeId]?.markup) {
                    emptyFaceTypeId = faceTypeId;
                    break;
                }
            }
            if (emptyFaceTypeId !== undefined) {
                if (!cards[cardId]?.[emptyFaceTypeId]) return;
                setCurrentlyOpenFace({ cardId, faceId: emptyFaceTypeId });
                setQuery('');
                return;
            }
        }
    }

    if (currentlyOpenFace) return <React.Fragment/>;

    return (
        <CodeckNavbarSignedIn>
            <Bootstrap.Navbar.Collapse>
                <Bootstrap.Form style={{width: '100%'}} onSubmit={handleUniversalSubmit}>
                    <Bootstrap.FormControl
                        ref={searchBoxRef}
                        type={'text'}
                        placeholder={`Find or Create Flashcard in "${deck.title}"`}
                        onChange={e => setQuery(e.target.value) }
                        value={query}
                    />
                </Bootstrap.Form>
            </Bootstrap.Navbar.Collapse>
            <div style={{width: '4px'}}/>
            <Bootstrap.Button
                variant={'light'}
                disabled={isConflictingSearchKey}
                onClick={onClickCreateFlashcard}>+</Bootstrap.Button>
        </CodeckNavbarSignedIn>
    )
}

export const CardsTableRow = (props: { cardId: string }): JSX.Element => {
    const { hydrateCardIfNecessary, deck, deleteCard, cards } = useContext(DeckContext);
    const { setCurrentlyOpenFace } = useContext(ExplodedDeckViewContext)
    const { inView, ref } = useInView();

    useEffect(() => {
        if (inView) {
            hydrateCardIfNecessary(props.cardId)
        }
    }, [inView]);

    const columns = Object.keys(deck.faceTypes)
        .sort((x, y) => deck.faceTypes[x].displayOrderHint - deck.faceTypes[y].displayOrderHint)
        .map(faceTypeId => (
            <td key={faceTypeId}>
                <div
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        if (!cards[props.cardId]?.[faceTypeId]) return;
                        setCurrentlyOpenFace({ cardId: props.cardId, faceId: faceTypeId });
                    }}
                >
                    <CachedFlashcardFaceThumbnail cardId={props.cardId} faceTypeId={faceTypeId}/>
                </div>
            </td>
        ))

    return (
        <tr ref={ref}>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <Dropdown>
                    <Dropdown.Toggle variant={'none'}>
                        <FiSettings/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => deleteCard(props.cardId)}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
            {columns}
        </tr>
    )
}

export const CardsTableHeading = (props: { faceType: FlashcardFaceType, id: string }): JSX.Element => {
    const { deck, updateFaceTypeUniqueness, deleteFaceType } = useContext(DeckContext);
    return (
        <td>
            <Bootstrap.DropdownButton title={props.faceType.title} variant={'none'} style={{width: '300px'}}>
                <Bootstrap.Dropdown.Item disabled={deck.searchKeyFaceId === props.id} onClick={() => deleteFaceType(props.id) }>
                    Delete
                </Bootstrap.Dropdown.Item>
                <Bootstrap.Dropdown.Divider/>
                <Bootstrap.Dropdown.Item onClick={() => updateFaceTypeUniqueness(props.id, !props.faceType.isLabel)}>
                    <span>Unique</span>
                    <span style={{float: 'right'}}>{ props.faceType.isLabel ? '✓' : '' }</span>
                </Bootstrap.Dropdown.Item>
                <Bootstrap.Dropdown.Item disabled={true}>
                    <span>Searchable</span>
                    <span style={{float: 'right'}}>{ deck.searchKeyFaceId === props.id ? '✓' : '' }</span>
                </Bootstrap.Dropdown.Item>
            </Bootstrap.DropdownButton>
        </td>
    )
}

const matchCards = (searchTerm: string, cards: FacesCache, searchKeyFaceId: string) => {
    const searchableCards = Object.keys(cards)
        .map(cardId => ({ cardId, faces: cards[cardId] }))
        .filter(card => card?.faces[searchKeyFaceId])

    return new Fuse(searchableCards, { keys: [`faces.${searchKeyFaceId}.markup`], threshold: 0.3 })
        .search(searchTerm)
        .map(result => result.item)
        .map(item => item.cardId)
}

export const CardsTable = (): JSX.Element => {
    const { deck, createFaceType, cards } = useContext(DeckContext);
    const { query, filteredCards } = useContext(ExplodedDeckViewContext);

    const [newFaceTypeTitle, setNewFaceTypeTitle] = useState('');
    const handleSubmitNewFaceType = (event?: FormEvent) => {
        if (newFaceTypeTitle.trim().length === 0) return;
        event?.preventDefault()
        createFaceType(newFaceTypeTitle)
            .then(() => setNewFaceTypeTitle(''))
    }

    const headings = Object.keys(deck.faceTypes)
        .sort((x, y) => deck.faceTypes[x].displayOrderHint - deck.faceTypes[y].displayOrderHint)
        .map(id => <CardsTableHeading key={id} id={id} faceType={deck.faceTypes[id]}/>);

    const rows = filteredCards.map(cardId => <CardsTableRow cardId={cardId} key={cardId}/>);

    return (
        <div style={{overflowX: 'auto', minHeight: '200px'}}>
            <Bootstrap.Table>
                <thead>
                <tr>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center'}}>
                        <Dropdown>
                            <Dropdown.Toggle variant={'none'} disabled={true}>
                                <FiSettings/>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </td>
                    {headings}
                    <td>
                        <Bootstrap.Form onSubmit={handleSubmitNewFaceType}>
                            <Bootstrap.ButtonGroup style={{width: '300px'}}>
                                <Bootstrap.FormControl
                                    type={'text'}
                                    placeholder={'New Face Type'}
                                    value={newFaceTypeTitle}
                                    onChange={(e) => setNewFaceTypeTitle(e.target.value)}
                                />
                                <Bootstrap.Button
                                    onClick={handleSubmitNewFaceType}
                                    disabled={newFaceTypeTitle.trim().length === 0}
                                >+</Bootstrap.Button>
                            </Bootstrap.ButtonGroup>
                        </Bootstrap.Form>
                    </td>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Bootstrap.Table>
        </div>
    )
}

export const ExplodedDeckView = (): JSX.Element => {
    const auth = useContext(CodeckAuthContext);
    const { deck, cards, id: deckId } = useContext(DeckContext);
    const [query, setQuery] = useState<string>('');
    const [currentlyOpenFace, setCurrentlyOpenFace] = useState<FaceReference>();
    const history = useHistory();

    const closeFaceEditorModal = (moveToNextCard: boolean): void => {
        // If currentlyOpenFace is undefined then someone called this function
        // at the wrong time.
        if (moveToNextCard && currentlyOpenFace) {
            const faceTypeIds = Object.keys(deck.faceTypes)
                .sort((x, y) => deck.faceTypes[x].displayOrderHint - deck.faceTypes[y].displayOrderHint)
            for (const faceTypeId of faceTypeIds) {
                if (faceTypeId === currentlyOpenFace!.faceId) continue;
                const markup = cards[currentlyOpenFace!.cardId]?.[faceTypeId]?.markup;
                if (!markup) {
                    setCurrentlyOpenFace({
                        cardId: currentlyOpenFace?.cardId,
                        faceId: faceTypeId
                    })
                    return;
                }
            }
        }
        setCurrentlyOpenFace(undefined);
    }

    const newestCards = (): Array<string> => Object.keys(cards)
        .map(id => ({ lastUpdatedAt: cards[id]?.[deck.searchKeyFaceId]?.lastUpdatedAt, id }))
        .sort((x, y) => x.lastUpdatedAt - y.lastUpdatedAt)
        .reverse()
        .map(card => card.id);

    const filteredCards = query.trim().length === 0 ? newestCards() : matchCards(query, cards, deck.searchKeyFaceId);

    return (
        <ExplodedDeckViewContext.Provider value={{filteredCards, query, setQuery, setCurrentlyOpenFace, currentlyOpenFace}}>
            <FaceEditorGuard face={currentlyOpenFace} close={closeFaceEditorModal}/>
            <CardsSearchBar/>
            <Bootstrap.Container fluid>
                <br/>
                <h2>
                    <Link to={'./../'}>
                        Decks
                    </Link>
                    <span>&nbsp;/&nbsp;</span>
                    <span>{deck.title}</span>
                    <Bootstrap.ButtonToolbar style={{ float: 'right' }}>
                        <Dropdown>
                            <Dropdown.Toggle variant={'primary'}>
                                <ImStack style={{ marginTop: '-3px' }}/>
                                <span>&nbsp;Study</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: '250px', textOverflow: 'clip' }}>
                                <Dropdown.Item onClick={() => history.push(`./../../study/deck/${deckId}`)}>
                                    <span style={{display: 'block', paddingBottom: '3px'}}><BsFilter style={{ marginTop: '-3px' }}/>&nbsp;Narrowing Reshuffle</span>
                                    <small style={{ whiteSpace: 'pre-wrap' }}>
                                        Correctly answered questions are eliminated until none remain.
                                        Remaining questions are reshuffled after each iteration. Repeat ad infinitum.
                                    </small>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Bootstrap.ButtonToolbar>
                </h2>
                <br/>
            </Bootstrap.Container>
            <br/>
            <Bootstrap.Container fluid>
                <CardsTable/>
                <NoCardsFound/>
            </Bootstrap.Container>
        </ExplodedDeckViewContext.Provider>
    )
}

const NoCardsFound = (): JSX.Element => {
    const { cards } = useContext(DeckContext);
    if (Object.keys(cards).length > 0) return <React.Fragment/>;
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <MdFilterNone style={{ fontSize: '100px' }}/>
            <br/>
            <h4>This deck is empty.</h4>
            <p>Use the text-bar at the top of the screen to create a new card.</p>
        </div>
    )
}
