import React, {useContext, useEffect, useState} from 'react'
import * as Bootstrap from 'react-bootstrap';
import {FlashcardDeck, GetQuestionResponseBody, Answer, ContentfulStudySessionQuestion} from 'dto-interfaces';
import { useParams as useRouteParams, Link } from 'react-router-dom'
import {fetchDeck} from './DeckContext';
import {AuthContextInterface, CodeckAuthContext} from './AuthContext';
import ReactCardFlip from 'react-card-flip';
import {FlashcardFaceThumbnail, FlexibleFlashcardFaceView} from './FlashcardFaceView';
import './StudyDeckView.css'
import {delay} from './util/PromiseUtil';
import {smartFetch} from './util/FetchUtils'
import {CodeckNavbar} from './CodeckNavbar';

export const fetchQuestion = async (auth: AuthContextInterface, deckId: string): Promise<GetQuestionResponseBody> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/questions/shuffled/next`, {
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json'
        }
    });
    if (!response.ok) throw new Error(response.statusText);
    return await response.json();
}

export const postAnswer = async (auth: AuthContextInterface, question: ContentfulStudySessionQuestion, answer: Answer): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/study-session/shuffled/${question.sessionId}`
        + `/questions/${question.id}/answer`, {
        method: 'POST',
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(answer)
    });
    if (!response.ok) throw new Error(response.statusText);
}

export const FlippapbleFlashCard = (): JSX.Element => {
    const { isShowingBackside, currentShuffledQuestion } = useContext(StudyDeckViewContext);
    return (
        <ReactCardFlip isFlipped={isShowingBackside}>
            <div className={'present-card'}>
                <FlexibleFlashcardFaceView markup={!isShowingBackside ? currentShuffledQuestion?.content.promptFace.markup : ''} resizable={true}/>
            </div>
            <div className={'present-card'}>
               <FlexibleFlashcardFaceView markup={isShowingBackside ? currentShuffledQuestion?.content.responseFace.markup : ''} resizable={true}/>
            </div>
        </ReactCardFlip>
    )
}

const StudyDeckViewNavbar = (): JSX.Element  => {
    const { deck, deckId, isDeckCovered } = useContext(StudyDeckViewContext);
    return (
        <CodeckNavbar>
            <Bootstrap.Navbar.Collapse>
                <Bootstrap.Navbar.Text style={{ color: 'white' }}>
                    { deck ? deck.title : <Bootstrap.Placeholder style={{width: '100px'}}/> }
                </Bootstrap.Navbar.Text>
            </Bootstrap.Navbar.Collapse>
            <Link to={`../../edit/deck/${deckId}`}>
                <Bootstrap.Button variant={isDeckCovered ? 'success' : 'light'}>Done</Bootstrap.Button>
            </Link>
        </CodeckNavbar>
    )
}

interface StudyDeckViewContextInterface {
    readonly deck?: FlashcardDeck,
    readonly deckId: string,
    readonly isShowingBackside: boolean,
    readonly nextQuestion: (correct: boolean) => void,
    readonly currentShuffledQuestion?: ContentfulStudySessionQuestion,
    readonly refetchQuestion: () => void,
    readonly fetchError?: any,
    readonly isDeckCovered: boolean
}
export const StudyDeckViewContext = React.createContext<StudyDeckViewContextInterface>({
    get deck(): FlashcardDeck { throw new Error() },
    get fetchError(): any { throw new Error() },
    get deckId(): string { throw new Error() },
    get isShowingBackside(): boolean { throw new Error() },
    get currentShuffledQuestion(): ContentfulStudySessionQuestion { throw new Error() },
    nextQuestion: () => { throw new Error() },
    refetchQuestion: () => { throw new Error() },
    isDeckCovered: false
});

export const StudyDeckView = (): JSX.Element  => {
    const auth = useContext(CodeckAuthContext);
    const { id } = useRouteParams() as { id: string };
    const [deck, setDeck] = useState<FlashcardDeck>();
    const [fetchError, setFetchError] = useState<any>();
    const [isShowingBackside, setShowingBackside] = useState<boolean>(false);
    const [isLoadingNextQuestion, setLoadingNextQuestion] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<ContentfulStudySessionQuestion>();
    const [isDeckCovered, setCovered] = useState<boolean>(false);

    const refetchQuestion = () => {
        setLoadingNextQuestion(true);
        setFetchError(undefined);
        setCovered(false);
        const minWaitTime = delay(1000);
        fetchDeck(auth, id)
            .then(setDeck)
            .then(() => fetchQuestion(auth, id))
            .then(response => {
                setCurrentQuestion(response.question);
                setCovered(response.isDeckCovered);
            })
            .then(() => minWaitTime)
            .catch(e => {
                setFetchError(e);
                console.error(e)
            })
            .finally(() => setLoadingNextQuestion(false))
    }

    const nextQuestion = (correct: boolean) => {
        setFetchError(undefined);
        setLoadingNextQuestion(true);
        setCurrentQuestion(undefined);
        setCovered(false);
        const minWaitTime = delay(1000);
        postAnswer(auth, currentQuestion!, { correct })
            .then(() => fetchQuestion(auth, id))
            .then(response => {
                setCurrentQuestion(response.question);
                setCovered(response.isDeckCovered);
            })
            .catch(e => {
                setFetchError(e);
                console.error(e)
            })
            .then(() => minWaitTime)
            .finally(() => setLoadingNextQuestion(false))
    }

    useEffect(refetchQuestion, [id]);

    return (
        <StudyDeckViewContext.Provider value={{ deckId: id, deck, isShowingBackside, nextQuestion, currentShuffledQuestion: currentQuestion,
            refetchQuestion, fetchError, isDeckCovered }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    if (currentQuestion) {
                        setShowingBackside(!isShowingBackside)
                    }
                }}
            >
                {isLoadingNextQuestion ?
                    <React.Fragment>
                        <h2>Next Question</h2>
                        <span>Loading...</span>
                    </React.Fragment>
                    :
                    <FlashcardHolder/>
                }

                <div style={{ 'visibility': isShowingBackside ? 'visible' : 'hidden' }}>
                    <QuestionGrader/>
                </div>
            </div>
            <StudyDeckViewNavbar/>
        </StudyDeckViewContext.Provider>
    )
}

export const FlashcardHolder = (): JSX.Element => {
    const { deckId, deck, currentShuffledQuestion, isShowingBackside, refetchQuestion,
        fetchError } = useContext(StudyDeckViewContext);

    if (fetchError) {
        return (
            <div style={{ textAlign: 'center', padding: '5px' }}>
                <h3>Can't Reach Server</h3>
                <p>We're having trouble downloading new questions for you from the server.</p>
                <Bootstrap.Button onClick={refetchQuestion}>Try Again</Bootstrap.Button>
            </div>
        )
    }

    if (!currentShuffledQuestion) {
        return (
            <React.Fragment>
                <h3>No Questions Yet</h3>
                <div style={{ maxWidth: '500px' }}>
                    Make sure your deck contains at least one flash card and at least
                    one unique face type.
                </div>
                <br/>
                <Link to={`../../edit/deck/${deckId}`}>
                    <Bootstrap.Button>Edit Deck</Bootstrap.Button>
                </Link>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className={isShowingBackside ? 'hide-fade' : 'show-fade'} style={{ paddingBottom: '3px' }}>
                What is <b>{deck?.faceTypes[currentShuffledQuestion.answerFaceTypeId]?.title}</b>?
            </div>
            <FlippapbleFlashCard/>
        </React.Fragment>
    )
}

export const QuestionGrader = (): JSX.Element => {
    const { nextQuestion } = useContext(StudyDeckViewContext);

    return (
        <Bootstrap.ButtonToolbar style={{ padding: '10px' }}>
            <Bootstrap.Button variant={'outline-danger'} onClick={() => nextQuestion(false)}>
                I was Wrong
            </Bootstrap.Button>
            <div style={{width: '4px'}}/>
            <Bootstrap.Button variant={'outline-primary'} onClick={() => nextQuestion(true)}>
                I was Correct
            </Bootstrap.Button>

        </Bootstrap.ButtonToolbar>
    )
}
