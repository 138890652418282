import React from 'react'
import {Modal, Spinner} from 'react-bootstrap';

export function SyncingWithServerModal(props: { show: boolean }) {
    return (
        <Modal size="lg" centered show={props.show}>
            <Modal.Header>
                <Modal.Title>
                    Communicating with Server...
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please wait while your account is synced with the server.
                    This will take up to 60 seconds.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Spinner animation={"border"}/>
            </Modal.Footer>
        </Modal>
    );
}
