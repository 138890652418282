import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams as usePathArgs} from 'react-router-dom'
import {DeckListContext} from './DeckList';
import {AuthContextInterface, CodeckAuthContext} from './AuthContext';
import {FlashcardDeck, FlashcardFaceType, Flashcard, FlashcardFace} from 'dto-interfaces';
import {SyncingWithServerModal} from './SyncingWithServerModal';
import * as uuid from 'uuid';
import {deepCopy, deepEquals} from './util/ObjectUtils';
import {addFaceTypeToFaceCache, FacesCache, hydrate, purgeByCardId, purgeByFaceTypeId, set} from './FacesCache';
import {smartFetch} from './util/FetchUtils';

export const fetchDeck = async (auth: AuthContextInterface, id: string): Promise<FlashcardDeck> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${id}`, {
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json'
        }
    });
    if (!response.ok) throw new Error(response.statusText);
    return await response.json();
}

const fetchDeckIndex = async (auth: AuthContextInterface, id: string): Promise<{ [key: string]: Flashcard }> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${id}/card`, {
        headers: {
            ...(await auth.getAuthHeaders()),
            'Accept': 'application/json'
        }
    });
    if (!response.ok) throw new Error(response.statusText);
    return await response.json();
}

const uploadFaceType = async (auth: AuthContextInterface, deckId: string, id: string, type: FlashcardFaceType): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/face-types/${id}`, {
        method: 'POST',
        headers: {
            ...(await auth.getAuthHeaders()),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(type)
    });
    if (!response.ok) throw new Error(response.statusText);
};

const sendFaceTypeUniquenessUpdateRequest = async (auth: AuthContextInterface, deckId: string, id: string, isUnique: boolean): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/face-types/${id}/is-label`, {
        method: 'PUT',
        headers: {
            ...(await auth.getAuthHeaders()),
            'Content-Type': 'text/plain'
        },
        body: `${isUnique}`
    });
    if (!response.ok) throw new Error(response.statusText);
};

const sendDeleteFaceTypeRequest = async (auth: AuthContextInterface, deckId: string, id: string): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/face-types/${id}`, {
        method: 'DELETE',
        headers: {
            ...(await auth.getAuthHeaders()),
        }
    });
    if (!response.ok) throw new Error(response.statusText);
};

const sendDeleteCardRequest = async (auth: AuthContextInterface, deckId: string, id: string): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/card/${id}`, {
        method: 'DELETE',
        headers: {
            ...(await auth.getAuthHeaders()),
        }
    });
    if (!response.ok) throw new Error(response.statusText);
};

const uploadCardFace = async (auth: AuthContextInterface, deckId: string, cardId: string, faceTypeId: string, face: FlashcardFace): Promise<void> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/card/${cardId}/faces/${faceTypeId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'text/plain',
            'Timestamp': `${face.lastUpdatedAt}`,
            ...(await auth.getAuthHeaders()),
        },
        body: face.markup
    });
    if (!response.ok) throw new Error(response.statusText);
};

const defaultFaceType = (title: string): FlashcardFaceType => ({
    title,
    isLabel: false,
    displayOrderHint: Date.now()
});

const fetchCard = async (auth: AuthContextInterface, deckId: string, cardId: string): Promise<Flashcard> => {
    const response = await smartFetch(`${auth.fireflyApiUrl}/deck/${deckId}/card/${cardId}/faces`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...(await auth.getAuthHeaders()),
        }
    });
    if (!response.ok) throw new Error(response.statusText);
    return { faces: await response.json() };
};

export interface DeckContextInterface {
    readonly id: string,
    readonly deck: FlashcardDeck,
    readonly createFaceType: (title: string) => Promise<void>,
    readonly updateFaceTypeUniqueness: (faceTypeId: string, isUnique: boolean) => void,
    readonly deleteFaceType: (id: string) => void,
    readonly cards: FacesCache,
    readonly updateCardFace: (cardId: string, faceTypeId: string, markup: string) => void,
    readonly hydrateCardIfNecessary: (cardId: string) => void,
    readonly createNewCard: (title: string) => void,
    readonly deleteCard: (id: string) => void
}

export const DeckContext = React.createContext<DeckContextInterface>({
    get id(): string { throw new Error('not implemented') },
    get deck(): FlashcardDeck { throw new Error('not implemented') },
    createFaceType: () => { throw new Error('not implemented') },
    updateFaceTypeUniqueness: () => { throw new Error('not implemented') },
    deleteFaceType: () => { throw new Error('not implemented') },
    updateCardFace: () => { throw new Error('not implemented') },
    hydrateCardIfNecessary: () => { throw new Error('not implemented') },
    createNewCard: () => { throw new Error('not implemented') },
    deleteCard: () => { throw new Error('not implemented') },
    get cards(): FacesCache { throw new Error('not implemented') },
})

const addFaceType = (id: string, faceType: FlashcardFaceType) => (deck?: FlashcardDeck) => {
    const newDeck = deepCopy(deck);
    newDeck!.faceTypes[id] = faceType;
    return newDeck;
}

const setFaceTypeUniqueness = (id: string, isUnique: boolean) => (deck?: FlashcardDeck) => {
    const newDeck = deepCopy(deck);
    newDeck!.faceTypes[id].isLabel = isUnique;
    return newDeck;
}

const removeFaceType = (id: string) => (deck?: FlashcardDeck) => {
    const newDeck = deepCopy(deck!);
    delete newDeck.faceTypes[id];
    return newDeck;
}

export const DeckContextProvider = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const auth = useContext(CodeckAuthContext);
    const { id: deckId } = usePathArgs<{ id: string }>();
    const [isSyncing, setSyncing] = useState<boolean>(false);
    const [deck, setDeck] = useState<FlashcardDeck>();
    const history = useHistory();
    const [cards, setCards] = useState<FacesCache>({});


    useEffect(() => {
        setSyncing(true);
        fetchDeck(auth, deckId)
            .then(setDeck)
            .then(() => fetchDeckIndex(auth, deckId))
            .then(index => Object.keys(index).forEach(cardId => {
               Object.keys(index[cardId].faces).forEach(faceTypeId => {
                   const face = index[cardId].faces[faceTypeId]
                   setCards(hydrate(cardId, faceTypeId, face.lastUpdatedAt, face.markup))
               })
            }))
            .then(() => setSyncing(false))
            .catch(e => {
                console.error(e);
                history.push('/decks')
            })
            .finally(() => setSyncing(false))
        // TODO Cancel request
    }, [deckId, auth]);

    const createFaceType = useCallback((title: string) => {
        const id = uuid.v4();
        const faceType = defaultFaceType(title);
        setSyncing(true);
        return uploadFaceType(auth, deckId, id, faceType)
            .then(() => setDeck(addFaceType(id, faceType)))
            .then(() => setCards(addFaceTypeToFaceCache(id)))
            .catch(console.error)
            .finally(() => setSyncing(false))
    }, [deckId, auth]);

    const updateFaceTypeUniqueness = useCallback((faceTypeId: string, isUnique: boolean) => {
        setSyncing(true);
        sendFaceTypeUniquenessUpdateRequest(auth, deckId, faceTypeId, isUnique)
            .then(() => setDeck(setFaceTypeUniqueness(faceTypeId, isUnique)))
            .catch(console.error)
            .finally(() => setSyncing(false))
    }, [deckId, auth]);

    const deleteFaceType = useCallback((faceTypeId: string) => {
        setSyncing(true)
        sendDeleteFaceTypeRequest(auth, deckId, faceTypeId)
            .then(() => setDeck(removeFaceType(faceTypeId)))
            .then(() => setCards(purgeByFaceTypeId(faceTypeId)))
            .catch(console.error)
            .finally(() => setSyncing(false))
    }, [deckId, auth]);

    // The moment a deck is created an unnecessary request is made to the server to download
    // all of the faces for that deck. We can just assume that no faces exist..
    // TODO FIX
    const updateCardFace = useCallback((cardId: string, faceTypeId: string, markup: string) => {
        const face: FlashcardFace = { lastUpdatedAt: Date.now(), markup };
        setCards(set(cardId, faceTypeId, face.lastUpdatedAt, face.markup));
        uploadCardFace(auth, deckId, cardId, faceTypeId, face)
            .then(() => setCards(hydrate(cardId, faceTypeId, face.lastUpdatedAt, markup)))
            .catch(console.error)
    }, [deckId, deck, auth]);

    const createNewCard = useCallback((title: string) => {
        const cardId = uuid.v4();
        Object.keys(deck!.faceTypes)
            .filter(id => id !== deck!.searchKeyFaceId)
            .forEach(blankFaceTypeId => {
                setCards(hydrate(cardId, blankFaceTypeId, Date.now(), ''))
            });
        updateCardFace(cardId, deck!.searchKeyFaceId, title)
    }, [updateCardFace, deck]);



    const hydrateCardIfNecessary = useCallback((cardId: string) => {
        const expectedFaces = Object.keys(deck!.faceTypes);
        const knownFaces = new Set(Object.keys(cards[cardId] || {}));
        const isMissingFaceData = !expectedFaces.every(expected => knownFaces.has(expected))
        if (!isMissingFaceData) return;

        console.log(`Hydrating Card ${deckId}/${cardId} because it appears that some faces are missing from it.`)

        fetchCard(auth, deckId, cardId)
            .then(card => Object.keys(card.faces).forEach(faceTypeId => {
                const face = card.faces[faceTypeId];
                setCards(hydrate(cardId, faceTypeId, face.lastUpdatedAt, face.markup));
            }))
            .catch(console.error)

    }, [auth, cards, deckId]);

    const deleteCard = useCallback((cardId: string) => {
        sendDeleteCardRequest(auth, deckId, cardId)
            .then(() => setCards(purgeByCardId(cardId)))
            .catch(console.error)
    }, [auth, deckId])

    return (
       <React.Fragment>
           <SyncingWithServerModal show={isSyncing}/>
           { deck ?
               <DeckContext.Provider value={{
                   hydrateCardIfNecessary,
                   updateCardFace,
                   cards,
                   deck,
                   deleteCard,
                   id: deckId,
                   createFaceType,
                   updateFaceTypeUniqueness,
                   deleteFaceType,
                   createNewCard
               }}>
                   {props.children}
               </DeckContext.Provider> : null }
       </React.Fragment>
    )
}
