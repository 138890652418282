import React, {SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import highlightjs from 'highlight.js';
import './CodeckSourceEditor.scss'
import {CodeckLangSupportContext} from './CodeckLangSupport';

const replaceSpaces = (html: string) => {
    let isInTag = false;
    let newText = '';
    for (let i = 0; i < html.length; i++) {
        if (html.charAt(i) === '>') {
            isInTag = false;
        }
        if (html.charAt(i) === '<') {
            isInTag = true;
        }
        if (html.charAt(i) == ' ' && !isInTag) {
            newText += '&nbsp;';
            continue;
        }
        newText += html.charAt(i);
    }
    return newText;
}

/**
 * A flexible textarea which supports syntax-highlighting for codeck-lang.
 * This component should be a descendent of {@link CodeckLangSupportContext}, otherwise
 * syntax-highlighting will be disabled. This component will expand to fit the size
 * of its parent.
 */
export const CodeckSourceEditor = (props: {
    sourceCode: string,
    setSourceCode: (sourceCode: string) => void,
    style?: React.CSSProperties,
    autoFocus?: boolean
}): JSX.Element => {
    const langSupport = useContext(CodeckLangSupportContext);

    const highlight = (source: string): string => {
        if (langSupport.isReady) {
            source = highlightjs.highlight(source, { language: 'codeck-lang' }).value;
        }
        source = source.replaceAll('\n', '<br/>')
        source = source.replaceAll('\r', '<br/>');
        source = replaceSpaces(source);
        source = source += '<br/>'
        return source;
    }

    const renderDivRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (renderDivRef.current && textareaRef.current) {
            const renderDiv = renderDivRef.current;
            const textArea = textareaRef.current;
            const listener = () => {
                renderDiv.scrollTo(textArea.scrollLeft, textArea.scrollTop);
            }
            textArea.addEventListener('scroll', listener);
            return () => {
                textArea.removeEventListener('scroll', listener)
            };
        }
    }, [renderDivRef, textareaRef])

    const sharedStyles: React.CSSProperties = {
        padding: '5px',
        overflowY: 'scroll',
        overflow: 'scroll',
        whiteSpace: 'nowrap',
        ...props.style
    }

    return (
        <div style={{ position: 'relative' }} className={'codeck-source-editor'}>
            <div
                ref={renderDivRef}
                className={'hljs'}
                style={{
                    overflowWrap: 'anywhere',
                    ...sharedStyles
                }}
                dangerouslySetInnerHTML={{ __html: highlight(props.sourceCode) }}
            />
            <textarea
                ref={textareaRef}
                autoFocus={props.autoFocus}
                style={{
                    position: 'absolute',
                    top: 0,
                    background: 'transparent',
                    color: 'transparent',
                    caretColor: 'white',
                    padding: '0',
                    border: 'none',
                    resize: 'none',
                    ...sharedStyles
                }}
                value={props.sourceCode}
                onChange={event => props.setSourceCode(event.target.value)}
            />
        </div>
    )
}
