import React, {SetStateAction, useContext, useEffect, useState} from 'react'
import * as Bootstrap from 'react-bootstrap'
import {FlashcardFaceThumbnail} from './FlashcardFaceView';
import {Link} from 'react-router-dom'
import './AboutCodeckView.css'
import highlightjs from 'highlight.js'
import {CodeckNavbar} from './CodeckNavbar';
import {CodeckSourceEditor} from './lang/CodeckSourceEditor';
import {CodeckLayoutConfig, CodeckLayoutConfigContext} from './theme/CodeckLayoutConfig';
import {Row} from 'react-bootstrap';

export const EditorDemo = (props: React.PropsWithChildren<{
    initialSourceCode: string
}
>): JSX.Element => {
    const [sourceCode, setSourceCode] = useState<string>(props.initialSourceCode);
    const { cardThumbnailDimensions, cardBorderRadius } = useContext<CodeckLayoutConfig>(CodeckLayoutConfigContext);
    return (
        <React.Fragment>
            {props.children}
            <Bootstrap.Row>
                <Bootstrap.Col>
                    <div style={{ ...cardThumbnailDimensions }}>
                        <CodeckSourceEditor
                            sourceCode={sourceCode}
                            setSourceCode={setSourceCode}
                            style={{
                                borderRadius: cardBorderRadius,
                                ...cardThumbnailDimensions
                            }}
                        />
                    </div>
                </Bootstrap.Col>
                <Bootstrap.Col>
                    <FlashcardFaceThumbnail markup={sourceCode}/>
                </Bootstrap.Col>
            </Bootstrap.Row>
        </React.Fragment>
    )
}

export const AboutCodeckView = (): JSX.Element => {
    return (
        <React.Fragment>
            <CodeckNavbar>
                <Link to={'/app/decks'}>
                    <Bootstrap.Button style={{ float: 'right' }}  variant={'outline-light'}>
                        Sign In
                    </Bootstrap.Button>
                </Link>
            </CodeckNavbar>
            <Bootstrap.Container>
                <br/>
                <Bootstrap.Row>
                    <h1>A better way to study</h1>
                    <p>
                        Codeck is a web-based Flashcard application catered towards technical
                        students in the fields of Math, Science, and Engineering.
                    </p>
                    <p>

                        Create beautiful Flashcards using LaTeX, Markdown, and HTML.
                        Memorize quickly using Codeck's adaptive study algorithm.
                    </p>
                </Bootstrap.Row>
                <div style={{height: '20px'}}/>
                <EditorDemo initialSourceCode={'{{#math}}\n' +
                'x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}\n' +
                '{{/math}}\n' +
                'where\\\n' +
                '$a$ = ________, $b$ = ________,\n' +
                '$c$ = ________, '}>
                    <h3>Memorize Equations and Formulas</h3>
                    <p>
                        Codeck supports typesetting of equations and formulas using <b>LaTeX</b>.
                    </p>
                </EditorDemo>
                <br/>
                <EditorDemo initialSourceCode={"**Stages of Mitosis**\n- Prophase\n- Metaphase\n- Anaphase\n- Telophase "}>
                    <h3>Format with Markdown</h3>
                    <p>
                        Create lists, tables, and write richly formatted text using the built-in Markdown support.
                    </p>
                </EditorDemo>
                <br/>
                <h3>Study thoroughly with multifaceted Flashcards</h3>
                <p>
                    Codeck will generate multiple questions from a singe multi-faceted Flashcard.
                    Multifaceted flashcards have an arbitrary number of sides, some of which are unique,
                    and others which are not. Codeck will quiz you on all combinations of sides,
                    always starting with a unique face.
                </p>
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <Bootstrap.Row>
                            <b>Ion (Unique)</b>
                        </Bootstrap.Row>
                        <FlashcardFaceThumbnail markup={"Oxalate"}/>
                    </Bootstrap.Col>
                    <Bootstrap.Col>
                        <b>Symbol (Unique)</b>
                        <FlashcardFaceThumbnail markup={'$ C_2O_4 $'}/>
                    </Bootstrap.Col>
                    <Bootstrap.Col>
                        <b>Electrons</b>
                        <FlashcardFaceThumbnail markup={'2-'}/>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <br/>
                <h3>Learn Quickly with Adaptive Practice</h3>
                <p>
                    Codeck prioritizes questions based on your mastery of the material.
                    Questions which you typically answer correctly are moved to the bottom of the
                    stack while those which you have trouble with are moved to the top.
                </p>
                <br/>
                <EditorDemo initialSourceCode={'````js\nconsole.log("hello world");\n````'}>
                    <h3>Syntax-Highlighting for Code Snippets</h3>
                    <p>Create Flashcards for your CS classes, using Codeck's built-in syntax highlighting.</p>
                </EditorDemo>
                <br/>
                <EditorDemo initialSourceCode={ "In cell biology, the part of the cell cycle \nin which replicated chromosomes \nare separated into two new nuclei. \nCell division gives rise to \ngenetically identical cells in which the\n total number of chromosomes \nis maintained. Also known as \nequational division"}>
                    <h3>Infinite Space</h3>
                    <p>Font-size is automatically scaled down, making it possible to create Flashcards of an arbitrary length.</p>
                </EditorDemo>
                <br/>
                <hr/>
                <div style={{textAlign: 'center', width: '100%'}}>
                    <span>&copy; Duncan Proctor 2021</span>
                    <br/>
                    <span><a href={'https://github.com/duncpro'}>https://github.com/duncpro</a></span>
                    <br/>
                    <span><a href={'mailto:dbp19a@my.fsu.edu'}>dbp19a@my.fsu.edu</a></span>
                    <br/>
                </div>
                <br/>
            </Bootstrap.Container>
        </React.Fragment>
    )
}
