import React, {useContext} from 'react'
import {Modal, Spinner, Card, Button} from 'react-bootstrap';
import {DeckListContext, DeckListContextInterface} from './DeckList';
import {CodeckAuthContext} from './AuthContext';

export function AccountModal(props: { show: boolean, setShowing: (arg0: boolean) => void }) {
    return (
        <Modal size="lg" centered show={props.show} onHide={() => props.setShowing(false)}>
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    Your Account
                </Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <h3>Subscription</h3>
                <SubPane/>
            </Modal.Body>
        </Modal>
    );
}

const SubPane = () => {
    const deckList: DeckListContextInterface = useContext(DeckListContext);
    const auth = useContext(CodeckAuthContext);
    const cancel = async () => {
        const response = await fetch(`${auth.fireflyApiUrl}/sub`, {
            method: 'DELETE',
            headers: {
                ...(await auth.getAuthHeaders())
            }
        });
        console.log(response)
        await deckList.pull();
    }
    if (deckList.isSubscribed) {
        return (
            <React.Fragment>
                <p>You are subscribed to Codeck monthly.</p>
                <Button onClick={cancel}>Cancel Subscription</Button>
            </React.Fragment>
        )
    } else {
        return (
            <p>You are using Codeck free-tier.</p>
        )
    }
}
