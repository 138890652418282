import * as Bootstrap from 'react-bootstrap'
import React, {useContext, useState} from 'react'
import {CodeckBrand} from './CodeckBrand';
import {CgProfile} from '@react-icons/all-files/cg/CgProfile';
import {useHistory} from 'react-router-dom';
import {CodeckAuthContext} from './AuthContext';
import {AccountModal} from './AccountModal';
import {CodeckColorSchemeContext} from './theme/CodeckColorScheme';

export const CodeckNavbar = (props: React.PropsWithChildren<{
    mode?: 'sticky' | 'fixed' | 'none'
}>): JSX.Element => {
    const colorScheme = useContext(CodeckColorSchemeContext);
    return (
        <Bootstrap.Navbar style={{ backgroundColor: colorScheme.primaryColor }}
                          sticky={props.mode === 'sticky' || !props.mode ? 'top' : undefined}
                          fixed={props.mode === 'fixed' ? 'top' : undefined}>
            <Bootstrap.Container fluid>
                <CodeckBrand/>
                { props.children }
            </Bootstrap.Container>
        </Bootstrap.Navbar>
    )
}

export const CodeckNavbarSignedIn = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const history = useHistory();
    const authContext = useContext(CodeckAuthContext);
    const [isShowingAccount, setShowingAccount] = useState<boolean>(false);

    const onClickLogout = () => {
        authContext.logout();
        history.push('/');
    };

    return (
      <React.Fragment>
          <AccountModal show={isShowingAccount} setShowing={setShowingAccount}/>
          <CodeckNavbar>
              {props.children}
              <div style={{width: '5px'}}/>
              <Bootstrap.Dropdown align="end">
                  <Bootstrap.Dropdown.Toggle variant={'light'}>
                      <CgProfile/>
                  </Bootstrap.Dropdown.Toggle>
                  <Bootstrap.Dropdown.Menu>
                      <Bootstrap.Dropdown.Item onClick={() => setShowingAccount(true)}>
                          Manage Account
                      </Bootstrap.Dropdown.Item>

                          <Bootstrap.Dropdown.Item onClick={() => { window.location.href = "https://github.com/duncpro/codeck-issue-tracker/issues"; }}>
                              Report Issue
                          </Bootstrap.Dropdown.Item>
                      <Bootstrap.Dropdown.Item onClick={onClickLogout}>
                          Logout
                      </Bootstrap.Dropdown.Item>
                  </Bootstrap.Dropdown.Menu>
              </Bootstrap.Dropdown>
          </CodeckNavbar>
      </React.Fragment>
    );
}
