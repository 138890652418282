import React from 'react';
import ReactDOM from 'react-dom';
import './theme/bootstrap-override.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import deploymentEnvironment from './deployment-environment.json'
import Amplify from 'aws-amplify'

if ((deploymentEnvironment as any).awsCognitoConfig) {
    Amplify.configure({
        Auth: (deploymentEnvironment as any).awsCognitoConfig
    })
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
