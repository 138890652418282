import React, {FormEvent, SetStateAction, useContext, useState} from 'react'
import * as Bootstrap from 'react-bootstrap'
import {DeckListContext, asArray, DeckListContextInterface} from './DeckList';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom'
import {CgProfile} from '@react-icons/all-files/cg/CgProfile'
import {FiSettings} from '@react-icons/all-files/fi/FiSettings'
import {CodeckAuthContext} from './AuthContext';
import {CodeckNavbarSignedIn} from './CodeckNavbar';
import {Button, Dropdown, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap';
import {MdFilterNone} from '@react-icons/all-files/md/MdFilterNone'

interface DeckListViewContextInterface {
    readonly query: string,
    setQuery: React.Dispatch<SetStateAction<string>>,
    readonly filteredDecks: Array<string>
}

const DeckListViewContext = React.createContext<DeckListViewContextInterface>({
    query: '',
    setQuery: () => { throw new Error('not implemented') },
    filteredDecks: []
});

const useCoverage = (deckId: string): number | undefined => {
    const { coverage } = useContext(DeckListContext);
    const selectedCoverageTimeframe = coverage?.selectedCoverageTimeframe;
    if (!selectedCoverageTimeframe) return;

    return coverage?.allReports[selectedCoverageTimeframe]?.[deckId];
}

const DeckCoverageStatus = (props: { deckId: string }): JSX.Element => {
    const coverage = useCoverage(props.deckId);

    if (coverage === undefined) {
        return  <Bootstrap.Placeholder style={{width: '30px'}}/>
    }

    if (coverage === -1) {
        const renderTooltip = (props: any) => (
            <Tooltip {...props}>
                This deck does not contain any questions.
            </Tooltip>
        );
        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}>
                <span>N/A</span>
            </OverlayTrigger>
        )
    }

    let fontColorClass;
    if (coverage < 0.7) {
        fontColorClass = 'text-danger'
    } else if (coverage < 0.8) {
        fontColorClass = 'text-warning'
    } else {
        fontColorClass = 'text-success'
    }

    return <span className={fontColorClass}>{ (coverage * 100).toFixed(1) }%</span>
}

const DeckListRow = (props: { deckId: string }): JSX.Element => {
    const [isDeleting, setDeleting] = useState<boolean>(false);
    const { cache, deleteDeck } = useContext(DeckListContext);
    const title = cache[props.deckId]?.title
    const history = useHistory();

    const handleClickDelete = () => {
        setDeleting(true);
        deleteDeck(props.deckId)
            .catch(console.error)
            .finally(() => setDeleting(false))
    }

    const isLoading = isDeleting;

    const open = () => history.push(`/app/edit/deck/${props.deckId}`);

    return (
        <tr style={{cursor: 'pointer'}}>
            <td style={{ verticalAlign: 'middle' }} onClick={open}>
                {title}
            </td>
            <td style={{ textAlign: 'center', verticalAlign: 'middle'}}  onClick={open}>
                <DeckCoverageStatus deckId={props.deckId}/>
            </td>
            <td style={{ verticalAlign: 'middle'}}>
                <Bootstrap.Dropdown align="end">
                    <Bootstrap.Dropdown.Toggle variant={'link'} disabled={isLoading}>
                        { isLoading ? <Spinner animation={'border'} size={'sm'}/> : <FiSettings/> }

                    </Bootstrap.Dropdown.Toggle>
                    <Bootstrap.Dropdown.Menu>
                        <Bootstrap.Dropdown.Item onClick={handleClickDelete}>
                            Delete
                        </Bootstrap.Dropdown.Item>
                    </Bootstrap.Dropdown.Menu>
                </Bootstrap.Dropdown>
            </td>
        </tr>
    )
}

const CoverageTimeframeLabel = (props: {millis?: number}): JSX.Element => {
    const millisFromDays = (days: number) => days * 24 * 60 * 60 * 1000;
    let component: JSX.Element = (
        <span>
            {'('}
             <Bootstrap.Placeholder style={{width: '30px'}}/>
            {')'}
        </span>
    )
    switch (props.millis) {
        case millisFromDays(1):
            component = <span>{ '(24 hours)'}</span>
            break;
        case millisFromDays(7):
            component =  <span>{ '(7 days)'}</span>
            break;
        case millisFromDays(14):
            component =  <span>{ '(2 weeks)'}</span>
            break;
    }
    return component;
}

const CoverageSelectionItem = (props: { timeframeMillis: number }): JSX.Element => {
    const { coverage, setSelectedCoverageTimeframe } = useContext(DeckListContext);
    return (
        <Dropdown.Item onClick={() => setSelectedCoverageTimeframe(props.timeframeMillis)}>
            <CoverageTimeframeLabel millis={props.timeframeMillis}/>
            <span style={{
                float: 'right',
                visibility: coverage?.selectedCoverageTimeframe === props.timeframeMillis ? 'visible' : 'hidden'}}
            >
                <span>{ '✓' }</span>
           </span>
        </Dropdown.Item>
    )
}

const CoverageSelectionItems = (): JSX.Element => {
    const deckListContext = useContext(DeckListContext);
    const availableTimeframes: Array<number> = (deckListContext.coverage?.availableTimeframes || [])
    const listItems = availableTimeframes
        .sort().reverse()
        .map(timeframeMillis => <CoverageSelectionItem key={timeframeMillis} timeframeMillis={timeframeMillis}/>)

    return (
        <React.Fragment>
            { listItems }
        </React.Fragment>
    )
}

const DeckListTable = (): JSX.Element => {
    const { query, filteredDecks} = useContext(DeckListViewContext);
    const { cache, deleteDeck, coverage } = useContext(DeckListContext);
    const history = useHistory();

    const rows = filteredDecks.map(deckId => <DeckListRow deckId={deckId} key={deckId} />)

    return (
        <Bootstrap.Container fluid>
            { Object.keys(cache).length === 0 ? <NoDecksFound/> :
                <Bootstrap.Table>
                    <thead>
                    <tr>
                        <td style={{width: '100%'}}>Title</td>
                        <td>
                            <Dropdown>
                                <Dropdown.Toggle variant={'none'}>
                                    <span>Coverage</span>
                                    <span>&nbsp;<CoverageTimeframeLabel millis={coverage?.selectedCoverageTimeframe}/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <small style={{padding: '5px', display: 'block'}}>
                                        Coverage measures the percentage of questions within
                                        a deck which you have answered correctly in your most recent
                                        attempt during the given timeframe.
                                    </small>
                                    <Dropdown.Divider/>
                                    <CoverageSelectionItems/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Bootstrap.Table>
            }
        </Bootstrap.Container>
    )
}

const DeckListSearchBar = (): JSX.Element => {
    const { createDeck, cache, syncing } = useContext(DeckListContext);
    const { query, setQuery, filteredDecks } = useContext(DeckListViewContext);
    const [isCreatingDeck, setCreatingDeck] = useState<boolean>(false);
    const history = useHistory();

    const canCreateDeck = (!asArray(cache).some(deck => deck.title === query)
        && query.trim().length !== 0) && !syncing && !isCreatingDeck

    const handleClickCreateDeck = () => {
        setCreatingDeck(true);
        let createdId: string;
        createDeck(query)
            .then(id => createdId = id)
            .catch(console.error)
            .finally(() => setCreatingDeck(false))
            .finally(() => {
                if (createdId) {
                    history.push(`/app/edit/deck/${createdId}`)
                }
            })
    }

    const handleUniversalSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (filteredDecks.length > 0) {
            history.push(`/app/edit/deck/${filteredDecks[0]}`);
            return;
        }
        if (canCreateDeck) {
            handleClickCreateDeck();
        }

    }

    return (
        <CodeckNavbarSignedIn>
            <Bootstrap.Navbar.Collapse>
                <Bootstrap.Form style={{width: '100%'}} onSubmit={handleUniversalSubmit}>
                    <Bootstrap.FormControl
                        type={'text'}
                        placeholder={'Find or Create Deck'}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        autoFocus
                    />
                </Bootstrap.Form>
                <div style={{width: '5px'}}/>
                <Bootstrap.Button variant={'light'} onClick={handleClickCreateDeck} disabled={!canCreateDeck}>
                    { isCreatingDeck ? <Spinner animation={'border'} size={'sm'}/> : <span>+</span>}
                </Bootstrap.Button>
            </Bootstrap.Navbar.Collapse>
        </CodeckNavbarSignedIn>
    )
}

export const DeckListView = (): JSX.Element => {
    const [query, setQuery] = useState<string>('');
    const deckList = useContext(DeckListContext);
    const decks = asArray(deckList.cache);

    const filteredDecks = (query.trim().length === 0 ? decks : (new Fuse(decks, { keys: ['title'] })
        .search(query)
        .map(searchResult => searchResult.item)))
        .map(item => item.id);

    return (
        <DeckListViewContext.Provider value={{ query, setQuery, filteredDecks }}>
            <DeckListSearchBar/>
            <br/>
            <SubscriptionAlert/>
            <Bootstrap.Container fluid>
                <h2>Decks</h2>
            </Bootstrap.Container>
            <br/>
            { deckList.syncing ? <SyncingMessage/> : <DeckListTable/> }
        </DeckListViewContext.Provider>
    )
}

const SubscriptionAlert = () => {
    const { isSubscribed } = useContext(DeckListContext);
    const authContext = useContext(CodeckAuthContext);

    const postNewSubscription = async () => {
        const response = await fetch(authContext.fireflyApiUrl + '/sub', {
            headers: {
                ...await authContext.getAuthHeaders(),
                'Accept': 'text/plain'
            },
            method: 'POST',
        });

        if (response.ok) {
            window.location.href = await response.text();
        }
    }

    if (isSubscribed === false) {
        return (
            <Bootstrap.Container fluid>
                <Bootstrap.Alert>
                    <Bootstrap.Alert.Heading>Codeck Free Tier</Bootstrap.Alert.Heading>
                    <p>
                        You are currently using <b>Codeck Free Tier</b>. With free tier you
                        can have up to <b>2 decks</b>. Subscribe to Codeck monthly for <b>$3.50</b> and get unlimited decks.
                    </p>
                    <div className="d-flex justify-content-end">
                        <Button onClick={postNewSubscription}>
                            Subscribe
                        </Button>
                    </div>
                </Bootstrap.Alert>
            </Bootstrap.Container>
        )
    }

    return null;
}

const NoDecksFound = (): JSX.Element => {
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <MdFilterNone style={{ fontSize: '100px' }}/>
            <br/>
            <h4>You don't have any decks yet.</h4>
            <p>Use the text-bar at the top of the screen to create your first deck.</p>
        </div>
    )
}

const SyncingMessage = (): JSX.Element => {
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '10px',
            textAlign: 'center'}}>
            <Spinner animation={'border'} style={{ width: '75px', height: '75px' }}/>
            <br/>
            <h4>Syncing with Server</h4>
            <p>
                Your account information is being synced with the server.
                This process can take up to 60 seconds.
            </p>

        </div>
    )
}
